import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //面包屑
    BreadCrumbs:['IdentificationRecord'],
    //tab
    ediTable:[
      { title: 'IdentificationRecord',
        name: '1',
        closable:false,
        disabled:true
      },
    ]
  },
  mutations: {},
  actions: {
  },
  modules: {
  }
})
