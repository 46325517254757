import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//导航字体
import '@/style/fontHeader/font.css';
//全部字体
import '@/style/fontCenter/font.css';
//中粗体
import '@/style/fontCenter/Center.css';
//常规字体
import '@/style/fontCenter/Conventional.css';
import { JSEncrypt } from 'jsencrypt'
//----------------------------------------------------------------------------------------------------------------
//登录加密
Vue.prototype.$encryptedData=function (publicKey, data){
// 加密
    const encryptor = new JSEncrypt()
    encryptor.setPublicKey(publicKey) // 设置公钥
    return encryptor.encrypt(data) // 对需要加密的数据进行加密
}
//引入Element-ui并配置
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
Vue.use(ElementUI);
//配置axios
import Axios from 'axios';
import VueAxios from "vue-axios";
import axios from "axios";
Vue.use(VueAxios,Axios);
axios.defaults.baseURL='https://www.cropopen.com:8009'
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
