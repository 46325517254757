import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    //客户端页面
    path: '/Cell',
    component:()=>import('@/views/HomePage/CellRecognition'),
    meta:{title:'Plantorganelle hunter'}
  },
  {
    //登录页面
    path: '/login',
    component:()=>import('@/views/Login/index'),
    meta:{title:'Login'}
  },
  {
    //权力说明
    path: '/power',
    component:()=>import('@/views/Power/index'),
    meta:{title:'The power that'}
  },
  {
    //后台页面配置
    path:'/back',
    component:()=>import('@/components/HackgroundPage'),
    meta:{title:'My background'},
    children:[
      {
        path:'/identification',
        component:()=>import('@/views/Identification'),
        meta:{title:'Identification record'}
      },
      {
        path:'/DataRequest',
        component:()=>import('@/views/DataRequest'),
        meta:{title:'Data Request'}
      },
      {
        path:'/detail',
        component:()=>import('@/views/Identification/detail'),
        meta:{title:'Identification record detail'}
      },
      {
        path:'/',redirect:'/identification'
      }
    ]
  },
  //默认路由
  {path:'/',redirect:'/Cell'},
  {
    //错误页面
    path: '*',
    component:()=>import('@/views/HomePage/CellRecognition'),
    meta:{title:'Plantorganelle hunter'}
  },
  {
    //英文文档
    path: '/english-guide',
    component:()=>import('@/views/scaleBar'),
    meta:{title:'English guide'}
  },
  {
    //中文文档
    path: '/chinese-instructions',
    component:()=>import('@/views/chinese-description'),
    meta:{title:'Chinese instructions'}
  }
]

const router = new VueRouter({
  routes
})
/* 更新浏览器标题方法 */
const updateTitle = function(route) {
  if (!route?.path?.startsWith('/redirect/')) {
    const names = [];
    if (route?.meta?.title) {
      names.push(route.meta.title);
    }
    const appName = process.env.VUE_APP_NAME;
    if (appName) {
      names.push(appName);
    }
    document.title = names.join(' - ');
  }
};
// 路由守卫
router.beforeEach((to, from, next) => {
  updateTitle(to);
  next()
});


export default router
